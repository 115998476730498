import React from "react";
import { PageProps } from "gatsby";
import { Layout } from "../layouts/layout";
import { Container } from "../layouts/container";
import { SEO } from "../seo";
import { Button, Grid, Link, Typography } from "@mui/material";
import { NavigateBefore } from "@mui/icons-material";
import { GatsbyShop } from "../utils/shop";

export default ({
  pageContext: { shop, root },
}: PageProps<{}, { shop: GatsbyShop; root: string }>) => {
  return (
    <Layout shop={shop} root={root}>
      <SEO title={`Mentions légales | ${shop.name}`} />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{
            top: "4.625rem",
          }}
        >
          <Grid xs={12} sm={10}>
            <Button
              color="primary"
              href={`${root}/`}
              startIcon={<NavigateBefore />}
              sx={{ mb: 1, textTransform: "capitalize" }}
            >
              Retourner à la boutique
            </Button>
            <Typography sx={{ mt: 3, mb: 2 }} variant="h1">
              Mentions légales
            </Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                Société :
              </Typography>{" "}
              {shop.legal?.company}
            </Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                Capital :
              </Typography>{" "}
              {shop.legal?.capital}
            </Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                Siège social :
              </Typography>{" "}
              {shop.legal?.registeredOffice}
            </Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                Numéro de téléphone :
              </Typography>{" "}
              {shop.legal?.phoneNumber}
            </Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                Siret :
              </Typography>{" "}
              {shop.legal?.siret}
            </Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                TVA Intracommunautaire :
              </Typography>{" "}
              {shop.legal?.intracommunityVAT}
            </Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                Responsable de publication :
              </Typography>{" "}
              {shop.legal?.responsible}
            </Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                Contact :
              </Typography>{" "}
              {shop.legal?.contact}
            </Typography>
            <Typography sx={{ mt: 3, mb: 3 }} variant="h2">
              Hébergeur
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>Netlify</Typography>
            <Typography>610 22nd Street, Suite 315</Typography>
            <Typography>San Francisco, CA 94107</Typography>
            <Typography sx={{ mt: 3, mb: 2 }}>
              Site Internet développé par{" "}
              <Link href="https://naonedit.fr" target="_blank">
                Naoned IT
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
